import {
  Button,
  Chip,
  Dialog,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import assertNever from 'assert-never';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AlertTriangle,
  ChevronLeft,
  ChevronRight,
  Search,
  X
} from 'react-feather';
import { Helmet } from 'react-helmet';
import { ButtonSplit } from '../../../../components/ButtonSplit';
import { InternalId } from '../../../../components/ConnectionId';
import { EmptySearchState } from '../../../../components/EmptySearchState';
import {
  ID_TO_ROW_KEY,
  ItemSorter,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { HelpIcon } from '../../../../components/HelpIcon';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { SearchInput } from '../../../../components/SearchInput';
import { IColumn } from '../../../../components/Table/Column';
import { COLORS } from '../../../../domainTypes/colors';
import { Doc } from '../../../../domainTypes/document';
import { AttributionRule } from '../../../../domainTypes/performanceLabelRules';
import { css, styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { useErrorLoggerWithLabel } from '../../../../hooks/useErrorLogger';
import { SortDirection } from '../../../../hooks/useSort';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useStringQueryParam } from '../../../../routes';
import { ARTICLES } from '../../../../services/beacon';
import { useColors } from '../../../../services/color';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { formatDateHuman } from '../../../../services/time';
import { onlyHostname } from '../../../../services/url';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { RuleCreateDialog } from '../../components/RuleCreateDialog';
import { RuleRenderer } from '../../components/RuleCreateDialog/RuleBuilder';
import { config } from '../../components/RuleCreateDialog/service';
import { RuleDeleteDialog } from '../../components/RuleDeleteDialog';
import { BatchImportDialog } from './BatchImportDialog';
import { exportRules, useRules } from './service';

type D = Doc<AttributionRule>;
type ColumnName =
  | 'id'
  | 'label'
  | 'rule'
  | 'constraints'
  | 'createdAt'
  | 'actions';
type Column = IColumn<D, ColumnName, { refreshVisibleRules: () => void }>;

const RuleContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: ${(p) => p.theme.spacing(0.5)}px;
`;

const RuleChip = ({ type }: { type: 'SITE' | 'PRODUCT' | 'PAGE' }) => {
  const width = 50;
  const COLORS = useColors();

  if (type === 'PRODUCT') {
    return null;
  }
  if (type === 'PAGE') {
    return (
      <Chip
        size="small"
        label="Page"
        style={{
          color: COLORS.blue.blue6,
          backgroundColor: COLORS.blue.blue1,
          width
        }}
      />
    );
  }
  if (type === 'SITE') {
    return (
      <Chip
        size="small"
        label="Site"
        style={{
          color: COLORS.purple.purple6,
          backgroundColor: COLORS.purple.purple1,
          width
        }}
      />
    );
  }

  return assertNever(type);
};

const Rule = ({ d }: { d: D }) => {
  const applications = d.data.apply;
  const els: React.ReactNode[] = [];

  const applyPage = applications.find((a) => a.type === 'PAGE');
  const applySite = applications.find((a) => a.type === 'SITE');

  if (applyPage) {
    const { value: url } = applyPage;
    els.push(
      <RuleContainer key={url}>
        <RuleChip type="PAGE" />
        <Typography
          className={css((_) => ({
            fontSize: '13px',
            fontWeight: 700
          }))}
        >
          {!url.startsWith('http') ? (
            <Tooltip
              title="Invalid URL: must start with http:// or https://"
              placement="top"
            >
              <Typography variant="body2" color="error">
                <AlertTriangle size={12} /> {url}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{url}</Typography>
          )}
        </Typography>
      </RuleContainer>
    );
  }

  if (applySite) {
    const { value: url } = applySite;
    let hostName = '';
    let error = false;

    try {
      hostName = onlyHostname(url);
    } catch (err) {
      error = true;
      console.log('error!');
    }

    return (
      <RuleContainer>
        <RuleChip type="SITE" />
        <Typography
          className={css(() => ({
            fontSize: '13px',
            fontWeight: 700
          }))}
        >
          {!error ? <span>{hostName}</span> : <span>{url} (!)</span>}
        </Typography>
      </RuleContainer>
    );
  }

  return <div>{els}</div>;
};

const Constraints = ({ d }: { d: D }) => {
  return (
    <div>
      <RuleRenderer variant="oneline" value={d.data.match} config={config} />
    </div>
  );
};

const Actions = ({
  d,
  refreshVisibleRules
}: {
  d: D;
  refreshVisibleRules: () => void;
}) => {
  const { space } = useCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();

  return (
    <>
      <IconButton onClick={openDialog}>
        <X size={18} />
      </IconButton>
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={closeDialog}>
          <RuleDeleteDialog
            spaceId={space.id}
            d={d}
            onClose={closeDialog}
            refreshVisibleRules={refreshVisibleRules}
          />
        </Dialog>
      )}
    </>
  );
};

const COLUMNS: Column[] = [
  {
    key: 'id',
    head: () => 'Rule ID',
    cell: (d) => <InternalId>{d.id}</InternalId>,
    headInfo: () =>
      'Unique identifier for this rule. Can be cross-referenced with transactions to know which rule was applied.',
    sortable: false,
    align: 'left',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'constraints',
    head: () => 'Match',
    headInfo: () =>
      'The conditions that must be met for this rule to apply to a commission.',
    cell: (d) => (
      <div style={{ wordBreak: 'break-all' }}>
        <Constraints d={d} />
      </div>
    ),
    align: 'left',
    width: 300,
    flexGrow: 5
  },
  {
    key: 'rule',
    head: () => 'Apply',
    headInfo: () =>
      'The attribution that will be applied when the rule matches.',
    cell: (d) => <Rule d={d} />,
    sortable: true,
    align: 'left',
    width: 300,
    flexGrow: 5
  },
  {
    key: 'createdAt',
    head: () => 'Created At',
    headInfo: () => 'The date and time this rule was created.',
    cell: (d) => formatDateHuman(d.data.createdAt, true),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'actions',
    head: () => '',
    alternateHead: () => 'Actions',
    cell: (d, o) => (
      <Actions d={d} refreshVisibleRules={o.refreshVisibleRules} />
    ),
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<D> = {
  createdAt: {
    key: 'createdAt',
    items: {
      sort: (d) => d.data.createdAt.toMillis(),
      dir: 'desc'
    }
  }
};

const RulesTable = ({
  rules,
  sorter,
  setSort,
  dir,
  refreshVisibleRules
}: {
  rules: D[];
  sorter: ItemSorter<D> | null;
  setSort: (v: [ItemSorter<D>, SortDirection | undefined]) => void;
  dir: SortDirection | undefined;
  refreshVisibleRules: () => void;
}) => {
  const [canDeleteRule] = useHasCurrentUserRequiredScopes(['rules.delete']);
  const columns = useMemo(() => {
    return canDeleteRule ? COLUMNS : COLUMNS.filter((c) => c.key !== 'actions');
  }, [canDeleteRule]);

  return (
    <RowsRenderer
      variant="contained"
      rows={rules}
      columns={columns}
      sorter={sorter || SORTERS.createdAt}
      renderHead={true}
      headProps={{ sticky: true, offset: 48 }}
      onHeadClick={(_, d) => setSort([SORTERS.createdAt, d])}
      sortDirection={dir}
      rowHeight={ROW_HEIGHTS.dense}
      rowToKey={ID_TO_ROW_KEY}
      chunkSize={100}
      rootMargin="400px"
      otherProps={{ refreshVisibleRules }}
    />
  );
};

const EmptyState = ({
  canCreateRules,
  openDialog
}: {
  canCreateRules: boolean;
  openDialog: () => void;
}) => {
  return (
    <Paper
      style={{
        borderRadius: 0,
        padding: '24px',
        textAlign: 'center'
      }}
    >
      <div style={{ margin: '0 auto 24px', maxWidth: '600px' }}>
        <img
          src="/images/empty-states/label-rules-empty-state.png"
          alt="Late Rules Empty State"
          style={{ width: '100%', marginBottom: '24px' }}
        />
        <Typography variant="h6" component="p" style={{ fontWeight: 'bold' }}>
          Create a new rule
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          paragraph
        >
          Rules help you manually attribute commissions from historical data, or
          platforms which don't support dynamic attribution via Smart Labels.
        </Typography>
        {canCreateRules ? (
          <Button variant="contained" color="primary" onClick={openDialog}>
            Create your first rule
          </Button>
        ) : (
          <Typography
            color="textSecondary"
            variant="body1"
            component="p"
            paragraph
          >
            However, you do not have permissions to create rules.
          </Typography>
        )}
      </div>
    </Paper>
  );
};

const PAGE_SIZE = 5;

const RulesBody = () => {
  const {
    space: { id: spaceId }
  } = useCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [canCreateRules] = useHasCurrentUserRequiredScopes(['rules.create']);
  const {
    dialogOpen: batchDialogOpen,
    closeDialog: batchDialogClose,
    openDialog: batchOpenDialog
  } = useDialogState();
  const [search, _setSearch] = useStringQueryParam('q');
  const setSearch = (q: string) => {
    setPage(1);
    _setSearch(q);
  };
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [page, setPage] = useState(1);

  const [rules, loading, error, reloadRules] = useRules(spaceId, {
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    search
  });
  useErrorLoggerWithLabel('useRules', error);

  useEffect(() => {
    if (!search) {
      setPage(1);
    }
  }, [search]);

  const showNext = () => {
    if (!rules || rules.length === 0) {
      return;
    }
    setPage(page + 1);
  };

  const showPrev = () => {
    setPage(Math.max(1, page - 1));
  };

  const refreshVisibleRules = () => {
    // cache was probably cleared at this point - and we are asking for another
    // page now.
    setPage(1);
    reloadRules();
  };

  return (
    <div>
      <>
        <PageToolbar sticky offset={DEFAULT_OFFSET}>
          <PageToolbarSection flex={1}>
            <Typography
              variant="h6"
              component="span"
              style={{
                marginRight: '12px',
                position: 'relative',
                fontWeight: 'bold',
                top: '-2px'
              }}
            >
              Rules
            </Typography>
            <HelpIcon color="blue" articleId={ARTICLES.labelRules.overview}>
              How to use
            </HelpIcon>
          </PageToolbarSection>
          <PageToolbarSection flex={1} justifyContent="flex-end">
            <div>
              {page > 1 && (
                <IconButton onClick={() => showPrev()}>
                  <ChevronLeft size={16} />
                </IconButton>
              )}
              <Typography
                variant="body2"
                component="span"
                style={{ display: 'inline-block', margin: '0 6px' }}
              >
                Page {page}
              </Typography>
              {rules && rules.length < PAGE_SIZE ? null : (
                <IconButton onClick={() => showNext()}>
                  <ChevronRight size={16} />
                </IconButton>
              )}
            </div>
            <SearchInput
              value={search}
              onChange={setSearch}
              autoFocus
              placeholder="Search by full SubId or full URL"
              width={300}
            />
            {canCreateRules && (
              <>
                &nbsp;&nbsp;
                <ButtonSplit
                  variant="contained"
                  color="primary"
                  onClick={openDialog}
                  otherActions={[
                    {
                      label: 'Import rules from file',
                      onClick: () => {
                        batchOpenDialog();
                      }
                    },
                    {
                      label: 'Export rules (last 10k)',
                      onClick: () => {
                        exportRules(spaceId);
                      }
                    }
                  ]}
                >
                  Create a rule
                </ButtonSplit>
              </>
            )}
          </PageToolbarSection>
        </PageToolbar>
        {loading && <Loader height={500} />}
        {!loading && rules && (
          <RulesTable
            rules={rules}
            sorter={sorter}
            dir={dir}
            setSort={setSort}
            refreshVisibleRules={refreshVisibleRules}
          />
        )}

        {rules && !rules.length && search && !loading && (
          <EmptySearchState
            color={COLORS.blue.blue6}
            bgColor={COLORS.blue.blue2}
            icon={Search}
            title={`No rules exactly match "${search}"`}
            message={
              <>
                Rule search is limited to either the exact SubID (tracking ID)
                and the full URL, including https://. You cannot search nested
                rules or rules based on "contains" logic.
                <br />
                <br />
                Make sure you have provided the{' '}
                <strong>full SubID or Page URL</strong> you want to find rules
                for. For URLs, always include the protocol (https://).
              </>
            }
          />
        )}

        {!loading && rules && !rules.length && !search && (
          <EmptyState canCreateRules={canCreateRules} openDialog={openDialog} />
        )}
      </>
      {dialogOpen && (
        <RuleCreateDialog
          spaceId={spaceId}
          open={dialogOpen}
          onClose={closeDialog}
          onRuleCreated={refreshVisibleRules}
        />
      )}
      {batchDialogOpen && (
        <BatchImportDialog
          spaceId={spaceId}
          open={batchDialogOpen}
          onClose={batchDialogClose}
          onRuleCreated={refreshVisibleRules}
        />
      )}
    </div>
  );
};

/*

This is a temporary version of this page, which still uses
all the old shapes we had in Firestore, but using PG as its
storage backend.
V4 will then transition everything over to be fully PG

*/
export const PagePerformanceRulesV3 = () => {
  useTrackMixpanelView('view_rules');
  const [canView] = useHasCurrentUserRequiredScopes(['rules.view']);

  return (
    <PerformancePageBody noTopPadding>
      <Helmet>
        <title>Rules | Affilimate</title>
      </Helmet>
      {canView ? <RulesBody /> : <NoPermissions />}
    </PerformancePageBody>
  );
};
