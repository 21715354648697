import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { Doc } from '../../../../domainTypes/document';
import { AttributionRule } from '../../../../domainTypes/performanceLabelRules';
import { useBackgroundJobs } from '../../../../hooks/useBackgroundJobs';
import { useFlushAnalyticsV2Cache } from '../../../../services/analyticsV2/cache';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { flushSalesCacheForSpace } from '../../../../services/sales/cache';
import { removeRule } from '../../pages/RulesV3/service';

export const RuleDeleteDialog = ({
  d,
  spaceId,
  onClose,
  refreshVisibleRules
}: {
  d: Doc<AttributionRule>;
  spaceId: string;
  onClose: () => void;
  refreshVisibleRules: () => void;
}) => {
  const [removeLinks, setRemoveLinks] = useState(true);
  const { addJob } = useBackgroundJobs();
  const flushAnalyticsCache = useFlushAnalyticsV2Cache();

  const onConfirm = async () => {
    addJob({
      job: async () => {
        if (removeLinks) {
          await callFirebaseFunction('labelRules-unlinkAttributionRule', {
            spaceId: spaceId,
            id: d.id
          });
        }
        await removeRule(spaceId, d.id);
      },
      onStart: () => {
        console.time('deleting rule');
        onClose();
        return {
          message: (
            <>
              <CircularProgress color="inherit" size={16} /> &nbsp; Removing
              rule...
            </>
          )
        };
      },
      onSuccess: () => {
        refreshVisibleRules();
        flushSalesCacheForSpace(spaceId);
        flushAnalyticsCache(spaceId);
        return {
          message: 'Removed rule successfully.'
        };
      },
      onError: (err) => {
        console.error(err);
        return {
          message: 'Removing this rule has failed. Please try again.'
        };
      }
    });
  };

  return (
    <>
      <DialogTitle>Delete a rule</DialogTitle>
      <DialogContent style={{ maxWidth: '600px' }}>
        <Typography variant="body1" component="p" paragraph>
          The most common reasons to delete a rule are:
        </Typography>
        <Typography variant="body1" component="ol" paragraph>
          <li>You added the rule by mistake</li>
          <li>You want to add different, more specific rules</li>
          <li>
            You created a 301 redirect and want to connect commissions to the
            page's new URL
          </li>
        </Typography>
        <hr style={{ border: '1px solid #EEE', margin: '24px 0' }} />
        <FormControlLabel
          label={
            <>
              <strong>Also unlink transactions (Recommended)</strong>
              <br />
              <Typography
                variant="body1"
                component="p"
                paragraph
                color="textSecondary"
              >
                Data added by the rule, like Page URL or Origin domain, will
                revert to the original value provided by the platform.
              </Typography>
            </>
          }
          control={
            <Checkbox
              checked={removeLinks}
              value={removeLinks}
              onChange={(ev) => {
                setRemoveLinks(ev.target.checked);
              }}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          variant="contained"
          color="secondary"
          onClick={onConfirm}
          pending="Removing..."
        >
          Delete rule
        </ButtonWithPromise>
      </DialogActions>
    </>
  );
};
